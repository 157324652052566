import React from 'react';

/**
 * SelectColor component description.
 *
 * @SelectColor
 *
 */

interface SelectColorProps {
  label?: string;
  value?: string;
  id?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SelectColor: React.FC<SelectColorProps> = ({
  label,
  value,
  id,
  onChange,
}) => {
  return (
    <div className="SelectColor">
      <div className="color-picker fl_row gp5 ai_c jc_s">
        <label htmlFor="primary-color" id={id}>
          {label}
        </label>
        <div className="color-box  fl_row ai_c">
          <span
            style={{ 'backgroundColor': value }}
            className="color-preview"
          ></span>
          <input
            type="text"
            name={id}
            id={id}
            value={value}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectColor;
