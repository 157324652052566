import React from 'react';
import AddImgIcon from '../../assets/icons/Add-img-Icon.png';

/**
 * SelectImage component description.
 *
 * @SelectImage
 *
 */

interface SelectImageProps {
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  src?: string;
  id?: string;
}

const SelectImage: React.FC<SelectImageProps> = ({
  label,
  onChange,
  src,
  id,
}) => {
  return (
    <div className="SelectImage">
      <div className="img-upload fl_row ai_c jc_s">
        <label className="cap" htmlFor={id} id={id}>
          {label}
        </label>

        <div className="img-box">
          <input
            type="file"
            name={id}
            id={id}
            accept="image/*"
            onChange={onChange}
          />
          <img src={src || AddImgIcon} alt={label + '-img'} width={50} />
        </div>
      </div>
    </div>
  );
};

export default SelectImage;
