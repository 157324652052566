import { all, takeEvery } from 'redux-saga/effects';
import es6promise from 'es6-promise';
import * as actionTypes from '../actions/actionTypes';
import {
  startLoginSaga,
  logoutSaga,
  forgetPasswordLinkSaga,
  resetPasswordLinkSaga,
} from './authSaga';
import {
  activateDeactivateCampanySaga,
  addEntrepriseSaga,
  editCompanyFinancialInfoSaga,
  editCompanyGeneralInfoSaga,
  getAllCompaniesSaga,
  getAllCompaniesWithEstablishementsSaga,
  getCompanyByIdFinancialInfosSaga,
  getCompanyByIdInfosSaga,
  getCompanyByIdSheetSaga,
} from './entrepriseSaga';
import {
  getCodesNAFSaga,
  verifyTaxesInfosEtablissementSaga,
  verifyTaxesInfosSaga,
} from './taxesInfosSaga';
import { getFormesJuridiquesSaga } from './formesJuridiquesSaga';
import {
  activateDeactivateEstablishementSaga,
  addEtablissementSaga,
  editEtablissementFinancialInfoSaga,
  editEtablissementGeneralInfoSaga,
  getAllEtablissementsSaga,
  getEtablissementByIdFinancialInfosSaga,
  getEtablissementByIdInfosSaga,
  getEtablissementByIdSheetSaga,
  getListStatutsEstablishementsSaga,
  updateStatusAbonnmentSaga,
} from './etablissementSaga';
import {
  addRoleSaga,
  getRolesListSaga,
  getRolesIdSaga,
  updateRoleSaga,
  deleteRoleSaga,
} from './roleSaga';
import {
  addUserSaga,
  checkUserByEmailSaga,
  deleteUserSaga,
  editUserSaga,
  getUserByIdSaga,
  getUsersListSaga,
  resetPasswordSaga,
  updatePasswordUserSaga,
  updateUserProfile,
} from './userSaga';

import { watchCatalogueSagas } from './catalogueSaga';
import {
  addCompanyMachineTypeSaga,
  fetchCompanyMachineTypesSaga,
  fetchMachineSuperTypesSaga,
  updateCampanyMachineTypes,
} from './TypeMachineSaga';
import {
  addEtablissmentTypeSuperSaga,
  EstablishmentListMachinesSaga,
  EstablishmentListMachineSupersSaga,
  syncEstablishmentMachinesSaga,
} from './SyncEtablisemmentSaga';
import { fetchFilteredListEtablissementsSaga } from './fiteredEtablissementSaga';
import {
  addAbonnementSaga,
  getAbonnementByIdSaga,
  getListAbonnementsClientSaga,
  getListIntervallesAbonnementSaga,
  listAbonnementSaga,
  updateAbonnementSaga,
} from './bonnementSaga';
import {
  fetchListFactureDetailsSaga,
  fetchListFactureSaga,
} from './factureSaga';
import { getCharCompanySaga, handleSubmitCompanyDataSaga } from './ChartCompanySaga';
es6promise.polyfill();
function* rootSaga() {
  yield all([
    watchCatalogueSagas(),
    takeEvery(actionTypes.LOGIN, startLoginSaga),
    takeEvery(actionTypes.LOGOUT, logoutSaga),
    takeEvery(actionTypes.ADD_ENTREPRISE, addEntrepriseSaga),
    takeEvery(actionTypes.VERIFY_TAXES_INFOS, verifyTaxesInfosSaga),
    takeEvery(actionTypes.GET_FORMES_JURIDIQUES, getFormesJuridiquesSaga),
    takeEvery(actionTypes.GET_FORMES_JURIDIQUES, getFormesJuridiquesSaga),
    takeEvery(actionTypes.GET_CODES_NAF, getCodesNAFSaga),
    //takeEvery(actionTypes.GET_BANK_ACCOUNT_TYPES, getBankAccountTypesSaga),
    takeEvery(actionTypes.GET_ALL_ENTREPRISE, getAllCompaniesSaga),
    takeEvery(actionTypes.GET_COMPANY_INFOS, getCompanyByIdInfosSaga),
    takeEvery(actionTypes.GET_COMPANY_SHEET, getCompanyByIdSheetSaga),
    takeEvery(
      actionTypes.GET_COMPANY_FINANCIAL_INFOS,
      getCompanyByIdFinancialInfosSaga,
    ),
    takeEvery(
      actionTypes.EDIT_ENTREPRISE_GENERAL_INFO,
      editCompanyGeneralInfoSaga,
    ),
    takeEvery(
      actionTypes.EDIT_ENTREPRISE_FINANCIAL_INFO,
      editCompanyFinancialInfoSaga,
    ),
    /////////////////////
    takeEvery(actionTypes.ADD_ETABLISSEMENT, addEtablissementSaga),
    takeEvery(
      actionTypes.VERIFY_TAXES_INFOS_ETABLISSEMENT,
      verifyTaxesInfosEtablissementSaga,
    ),
    takeEvery(actionTypes.GET_ALL_ETABLISSEMENT, getAllEtablissementsSaga),
    takeEvery(
      actionTypes.GET_ETABLISSEMENT_INFOS,
      getEtablissementByIdInfosSaga,
    ),
    takeEvery(
      actionTypes.GET_ETABLISSEMENT_SHEET,
      getEtablissementByIdSheetSaga,
    ),
    takeEvery(
      actionTypes.GET_ETABLISSEMENT_FINANCIAL_INFOS,
      getEtablissementByIdFinancialInfosSaga,
    ),
    takeEvery(
      actionTypes.EDIT_ETABLISSEMENT_GENERAL_INFO,
      editEtablissementGeneralInfoSaga,
    ),
    takeEvery(
      actionTypes.EDIT_ETABLISSEMENT_FINANCIAL_INFO,
      editEtablissementFinancialInfoSaga,
    ),
    takeEvery(actionTypes.GET_ROLES_LIST, getRolesListSaga),
    takeEvery(actionTypes.START_GET_ROLES_ID, getRolesIdSaga),
    takeEvery(actionTypes.UPDATE_ROLE_REQUEST, updateRoleSaga),
    takeEvery(actionTypes.DELETE_ROLE_REQUEST, deleteRoleSaga),
    // takeEvery(
    //   actionTypes.GET_ETABLISSEMENT_ROLES_LIST,
    //   getEstablishementRolesListSaga,
    // ),
    takeEvery(actionTypes.ADD_ROLE, addRoleSaga),
    takeEvery(actionTypes.ADD_USER, addUserSaga),
    takeEvery(
      actionTypes.GET_ALL_ENTREPRISE_ESTABLISHEMENTS,
      getAllCompaniesWithEstablishementsSaga,
    ),
    takeEvery(actionTypes.GET_USERS_LIST, getUsersListSaga),
    takeEvery(actionTypes.GET_USER_BY_ID, getUserByIdSaga),
    takeEvery(actionTypes.DELETE_USER, deleteUserSaga),
    takeEvery(actionTypes.EDIT_USER, editUserSaga),
    takeEvery(actionTypes.CHECK_USER_EMAIL, checkUserByEmailSaga),
    takeEvery(actionTypes.RESET_PASSWORD, resetPasswordSaga),
    takeEvery(
      actionTypes.FETCH_COMPANY_MACHINE_TYPES_REQUEST,
      fetchCompanyMachineTypesSaga,
    ),
    takeEvery(
      actionTypes.FETCH_MACHINE_SUPER_TYPES_REQUEST,
      fetchMachineSuperTypesSaga,
    ),
    takeEvery(
      actionTypes.ADD_COMPANY_MACHINE_TYPE_REQUEST,
      addCompanyMachineTypeSaga,
    ),
    takeEvery(
      actionTypes.UPDATE_COMPANY_MACHINE_TYPE_REQUEST,
      updateCampanyMachineTypes,
    ),
    takeEvery(
      actionTypes.SYNC_ESTABLISHMENT_MACHINES_REQUEST,
      syncEstablishmentMachinesSaga,
    ),
    takeEvery(
      actionTypes.ESTABLISHMENT_List_MACHINES_REQUEST,
      EstablishmentListMachinesSaga,
    ),
    takeEvery(
      actionTypes.ESTABLISHMENT_List_MACHINES_SUPER_REQUEST,
      EstablishmentListMachineSupersSaga,
    ),
    takeEvery(
      actionTypes.AFFECT_MACHINE_TO_TYPES_REQUEST,
      addEtablissmentTypeSuperSaga,
    ),
    takeEvery(
      actionTypes.GET_LIST_ESTABLISSEMENTS_REQUEST_ALL,
      fetchFilteredListEtablissementsSaga,
    ),
    takeEvery(
      actionTypes.GET_LIST_INTERVALLES_ABONNEMENT_REQUEST,
      getListIntervallesAbonnementSaga,
    ),
    takeEvery(actionTypes.GET_ABONNEMENT_BY_ID_REQUEST, getAbonnementByIdSaga),
    takeEvery(actionTypes.CREATE_ABONNEMENT_REQUEST, addAbonnementSaga),
    takeEvery(actionTypes.FETCH_ABONNEMENT_DATA_REQUEST, listAbonnementSaga),
    takeEvery(actionTypes.UPDATE_ABONNEMENT_REQUEST, updateAbonnementSaga),
    takeEvery(
      actionTypes.GET_LIST_STATUTS_ESTABLISSEMENTS_REQUEST,
      getListStatutsEstablishementsSaga,
    ),
    takeEvery(actionTypes.UPDATE_USER_SECTION_REQUEST, updateUserProfile),
    takeEvery(
      actionTypes.UPDATE_USER_PASSWORD_SECTION_REQUEST,
      updatePasswordUserSaga,
    ),
    takeEvery(
      actionTypes.UPDATE_ABONNEMENT_STATUS_REQUEST,
      updateStatusAbonnmentSaga,
    ),
    takeEvery(actionTypes.GET_LIST_FACTURE_REQUEST, fetchListFactureSaga),
    takeEvery(
      actionTypes.GET_FACTURE_BY_ID_REQUEST,
      fetchListFactureDetailsSaga,
    ),
    takeEvery(
      actionTypes.GET_LIST_ABONNEMENTS_CLIENT_REQUEST,
      getListAbonnementsClientSaga,
    ),
    takeEvery(
      actionTypes.ACTIVATE_DEACTIVATE_ESTABLISSEMENT_REQUEST,
      activateDeactivateEstablishementSaga,
    ),
    takeEvery(
      actionTypes.ACTIVATE_DEACTIVATE_CAMPANY_REQUEST,
      activateDeactivateCampanySaga,
    ),
    takeEvery(actionTypes.FORGET_PASSWORD_LINK_REQUEST, forgetPasswordLinkSaga),
    takeEvery(actionTypes.RESET_PASSWORD_LINK_REQUEST, resetPasswordLinkSaga),
    takeEvery(actionTypes.SUBMIT_COMPANY_DATA_REQUEST, handleSubmitCompanyDataSaga),
    takeEvery(actionTypes.GET_COMPANY_DATA_REQUEST, getCharCompanySaga),
  ]);
}
export default rootSaga;
