import React, { useState, ReactNode } from 'react';
import ButtonElement from '../../../components/button/button';

/**
 * EditStockModalContent component description.
 *
 * @EditStockModalContent
 *
 */

interface EditStockModalContentProps {
  slot: number;
  onClickCancel: () => void;
  onClickEdit: () => void;
  isMenu?: boolean;
  status?: 'exhausted' | 'sold-out-today' | 'in-stock';
  children: ReactNode;
  setActiveStatus: (
    status: 'exhausted' | 'sold-out-today' | 'in-stock',
  ) => void;
  activeStatus: 'exhausted' | 'sold-out-today' | 'in-stock';
  isCategorie?: boolean;
  categoryTxt?: boolean;
}

const EditStockModalContent: React.FC<EditStockModalContentProps> = ({
  slot,
  onClickCancel,
  onClickEdit,
  status,
  children,
  setActiveStatus,
  activeStatus,
  isMenu,
  isCategorie,
  categoryTxt,
}) => {
  return (
    <div className="EditStockModalContent">
      <div className="content-wrapper">
        <div className="content-header fl_col gp10">
          <p className="p18 w-700">
            {categoryTxt ? 'Modifier visibilité' : 'Modifier état de stock'}
          </p>
          <p className="w-600 p14 AuroMetalSaurus-txt">
            {categoryTxt ? "Categorie (s) sélectionnée(s)" : "Menu(s) sélectionné(s)"} <span className="slot">{slot}</span>
          </p>
        </div>
        <div className="content-body fl_col gp10">
          <div className="fl_col gp10 ai_c pd16-t-b filter-toggle pd8-r-l">
            <p className="p14 Independence-txt">
              {categoryTxt ? "Choisir un état de visibilité à appliquer " : "Choisir un état de stock à appliquer"}
            </p>
            <div className="toggle-switcher fl_row rd12 pd4 gp4">
              <div
                className={`item-toggle rd8 ${activeStatus === 'in-stock' ? 'active-item' : ''}`}
                onClick={() => setActiveStatus('in-stock')}
              >
                <p className="p18 w-600 pd8-t-b pd8-r-l">
                  {isCategorie ? 'Visible' : 'En Stock'}
                </p>
              </div>
              {!(isMenu || isCategorie) && (
                <div
                  className={`item-toggle rd8 ${activeStatus === 'sold-out-today' ? 'active-item' : ''}`}
                  onClick={() => setActiveStatus('sold-out-today')}
                >
                  <p className="p18 w-600 pd8-t-b pd8-r-l">
                    Epuisé Aujourd’hui
                  </p>
                </div>
              )}
              <div
                className={`item-toggle rd8 ${activeStatus === 'exhausted' ? 'active-item' : ''}`}
                onClick={() => setActiveStatus('exhausted')}
              >
                <p className="p18 w-600 pd8-t-b pd8-r-l">
                  {isCategorie ? 'Invisible' : 'Epuisé'}
                </p>
              </div>
            </div>
          </div>
          <div className="listings-cards fl_row fl_wrap gp8 pd16-t-b pd8-r-l">
            {children}
          </div>
        </div>
        <div className="modal-footer">
          <div className="footer-wrapper fl_row jc_fe gp20">
            <div className="fl-1">
              <ButtonElement
                type={'TextButton'}
                text="Annuler"
                buttonSize={'medium'}
                buttonStyle="btn-primary-light"
                onClick={onClickCancel}
              />
            </div>
            <div className="fl-2">
              <ButtonElement
                type={'TextButton'}
                text="Sauvegarder"
                buttonSize={'medium'}
                buttonStyle="btn-primary"
                onClick={onClickEdit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditStockModalContent;
