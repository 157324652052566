import React, { useEffect } from 'react';
import { languageData } from '../../i18n';
import Modal from '../../components/Modal/Modal';
import UserRoleModalContent from '../../components/Modal/UserRoleModalContent';
import UserAccessModalContent from '../../components/Modal/UserAccessModalContent';
import { useDispatch, useSelector } from 'react-redux';
import { serviceAppUserModels } from '../../models/services/module.appUser';
import { setUserFormDataDetails } from '../../store/actions/actions';
import { v4 as uuidv4 } from 'uuid';
import useGetRolesLogic from '../Roles/useGetRolesLogic';
import EditPermissionRoleRow from './EditPermissionRoleRow';
import { transformUserDetailsToPermissions } from '../../utils/transformUserDetailsToPermissions';
import AddNewIconCircle from "../../assets/icons/AddNewIconCirclesvg.svg"
interface permissionCardProps {
  cancelChanges: () => void;
  formHasErrors: boolean;
}
export default function EditUserPermissionsCard(props: permissionCardProps) {
  const dispatch = useDispatch();

  const [modalRole, SetmodalRole] = React.useState<boolean>(false);
  const [rowId, setRowId] = React.useState<string>('1');
  const [_, setPermissionRows] = React.useState<any[]>([]);
  const [modalAccess, SetmodalAccess] = React.useState<boolean>(false);

  const showRoleModal = (rowId: string) => {
    SetmodalRole((prev) => !prev);
    setRowId(rowId);
  };

  const showmodalAccess = (rowId: string) => {
    SetmodalAccess((prev) => !prev);
    setRowId(rowId);
  };

  const userFormData = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.userFormDataDetails,
  );
 
  const userDetails = useSelector(
    (state: { appUser: serviceAppUserModels.AppUserState }) =>
      state.appUser.userDetails,
  );

  useEffect(() => {
    const newPermissions = transformUserDetailsToPermissions(userDetails);
    setPermissionRows([...userFormData.permissions, ...newPermissions]);
    const data = {
      ...userFormData,
      permissions: newPermissions,
    };

    dispatch(setUserFormDataDetails(data));
  }, []);

  const { roles } = useGetRolesLogic();
  const handleAddPermissionRow = () => {
    const newPermission = {
      rolesId: [''],
      rowId: uuidv4(),
      isActive: true,
      chainesIds: [],
      restaurantsIds: [],
      chainesIdsToDelete: [],
      restaurantsIdsToDelete: [],
    };

    const updatedForm = {
      ...userFormData,
      permissions: [...userFormData.permissions, newPermission],
    };

    dispatch(setUserFormDataDetails(updatedForm));
  };
  const handleDeletePermissionRow = (selected: string) => {
    const updatedForm = {
      ...userFormData,
      permissions: userFormData.permissions.map((item) =>
        item.rowId === selected ? { ...item, toDelete: true } : item,
      ),
    };
    dispatch(setUserFormDataDetails(updatedForm));
  };

  return (
    <div className="EditUserPermissionsCard">
      <Modal
        version="custom"
        content={
          <UserRoleModalContent
            showRoleModal={() => showRoleModal(rowId)}
            rowId={rowId}
            roles={roles}
            type={'edit'}
            cancelChanges={props.cancelChanges}
          />
        }
        title={languageData.global.user.affectRole}
        visibility={modalRole}
        maxWidth={771}
        height={455}
      />
      <Modal
        version="custom"
        content={
          <UserAccessModalContent
            showmodalAccess={() => showmodalAccess(rowId)}
            rowId={rowId}
            type={'edit'}
          />
        }
        title={languageData.global.user.grantAccess}
        visibility={modalAccess}
        maxWidth={771}
        height={455}
      />
      <div className="PermissionsContainer fl_col gp20">
        <div className="header-block fl_row jc_s">
          <p className="p16 w-600 Nevada-txt pd22-t-b">
            {languageData.global.user.permissions}
          </p>
          {/* <ButtonElement
            buttonStyle="btn-primary"
            type={'TextButton'}
            text={"Ajouter rôle"}
            buttonSize={'large'}
            
            className=''
          /> */}
          <img className='AddRole-btn' src={AddNewIconCircle} alt="" onClick={handleAddPermissionRow}/>
        </div>
        <div className="fl_col permissionContent pd10-t-b">
          <div className="header-listing fl_row gp40">
            <p className="p14 w-700 OxfordBlue02-txt role-row">
              {languageData.global.role.roles}
            </p>
            <p className="p14 w-700 OxfordBlue02-txt fl-1 access-row">
              {languageData.global.user.access}
            </p>
          </div>
          {userFormData.permissions &&
            userFormData.permissions?.map((row, index) => {
              return (
                !row.toDelete && (
                  <EditPermissionRoleRow
                    key={index}
                    rowId={index.toString()}
                    row={row}
                    roles={roles}
                    formHasErrors={props.formHasErrors}
                    showRoleModal={() => showRoleModal(row.rowId)}
                    showmodalAccess={() => showmodalAccess(row.rowId)}
                    handleAddPermissionRow={handleAddPermissionRow}
                    handleDeletePermissionRow={() =>
                      handleDeletePermissionRow(row.rowId ?? '0')
                    }
                  />
                )
              );
            })}
        </div>
      </div>
    </div>
  );
}
