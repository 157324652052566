import axios from 'axios';
import axiosInstance, { BaseUrl, unAuthAxios } from './apiGate';

const commandEnLigneApi = {
  getChartData: async (CompanyId: string) => {
    try {
      const response = await axiosInstance.get(
        BaseUrl +
          `/ClickAndCollectGraphicChart/GetClickAndCollectGraphicChart?CompanyId=${CompanyId}`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  postChartData: async (formData: any , ) => {
    try {
      const response = await axios.post(
        BaseUrl + `/ClickAndCollectGraphicChart`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default commandEnLigneApi;
