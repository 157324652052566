import React, { useEffect, useState } from 'react';
import HeaderEtatDeStock from './HeaderEtatDeStock';
import ListingEtatDeStockContainer from './ListingEtatDeStock';
import ListingEtatDeStockMenus from './ListingMenusStock';
import ListingEtatDeStockCategories from './ListingCategorieStock';
import EtatDeStock from './EtatDeStock';
import WelcomeEtatStock from './WelcomeEtatStock';
import { useSelector } from 'react-redux';
import { serviceTabsModels } from '../../../../models/services/module.tabs';

export default function EtatDeStockContainer() {
  const [tab, settab] = useState('welcome');

  const handleTabChange = (newTab: string) => {
    settab(newTab);
  };
  const idEstablishment = localStorage.getItem('idEtablishement');
  const onConfirm = () => {
    settab('article');
  };
  const activeTabContent = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) =>
      state.tabs.activeTabContent,
  );
  useEffect(() => {
    if (activeTabContent?.isEtablishement) {
      settab('article');
    }
  }, [activeTabContent]);
  return (
    <div className="h-full">
      {!(tab == 'welcome') && (
        <HeaderEtatDeStock
          isEtab={activeTabContent?.isEtablishement}
          onBack={() => settab('welcome')}
          activeTab={tab}
          hideoptions={tab == 'welcome'}
          onChangeTab={handleTabChange}
        />
      )}

      {tab === 'article' ? (
        <ListingEtatDeStockContainer
          idEstablishment={idEstablishment as string}
        />
      ) : tab == 'menu' ? (
        <ListingEtatDeStockMenus
          idEstablishment={idEstablishment as string}
        ></ListingEtatDeStockMenus>
      ) : tab == 'category' ? (
        <ListingEtatDeStockCategories
          idEstablishment={idEstablishment as string}
        ></ListingEtatDeStockCategories>
      ) : (
        <WelcomeEtatStock onConfirm={onConfirm}></WelcomeEtatStock>
      )}
    </div>
  );
}
