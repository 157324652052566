import React, { useEffect, useState } from 'react';
import ColorPicker from '../../../components/Catalogue/ColorPicker';
import SaveIconWhiteBtn from '../../../assets/icons/SaveIconWhiteBtn.svg';
import AddImgIcon from '../../../assets/icons/Add-img-Icon.png';
import { useDispatch } from 'react-redux';
import {
  getChartDataRequest,
  submitChartDataRequest,
} from '../../../store/actions/actions';
import { useSelector } from 'react-redux';
import { serviceTabsModels } from '../../../models/services/module.tabs';
import SelectImage from '../../../components/Input/SelectImage';
import SelectColor from '../../../components/Input/SelectColor';

export default function CommandeEnLigneEntreprise() {
  const dispatch = useDispatch();
  const { loading, data, error } = useSelector(
    (state: { GetChartcompany: { loading: boolean; data: any; error: any } }) =>
      state.GetChartcompany,
  );

  const activeTabContent = useSelector(
    (state: { tabs: serviceTabsModels.TabsState }) =>
      state.tabs.activeTabContent,
  );

  const [formData, setFormData] = useState({
    id: data?.id || 0,
    companyId: activeTabContent?.id,
    primaryColor: '',
    secondaryColor: '',
    layoutColor: '',
    appLogoImage: null,
    appDarkLogoImage: null,
    appLightLogoImage: null,
    backgroundDesktopImage: null,
    backgroundTabletImage: null,
    backgroundMobileImage: null,
  });

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      id: data?.id || 0,
      companyId: activeTabContent?.id,
      primaryColor: data?.primaryColor || '',
      secondaryColor: data?.secondaryColor || '',
      layoutColor: data?.layoutColor || '',
      appLogoImage: data?.appLogoImage || null,
      appDarkLogoImage: data?.appDarkLogoImage || null,
      appLightLogoImage: data?.appLightLogoImage || null,
      backgroundDesktopImage: data?.backgroundDesktopImage || null,
      backgroundTabletImage: data?.backgroundTabletImage || null,
      backgroundMobileImage: data?.backgroundMobileImage || null,
    }));
  }, [data, activeTabContent]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: files ? files[0] : value,
      };
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const data = new FormData();

    // Append all fields to FormData
    data.append('id', formData.id.toString());
    data.append('companyId', formData.companyId.toString());
    data.append('primaryColor', formData.primaryColor);
    data.append('secondaryColor', formData.secondaryColor);
    data.append('layoutColor', formData.layoutColor);

    // Append images only if they are selected
    if (formData.appLogoImage)
      data.append('appLogoImage', formData.appLogoImage);
    if (formData.appDarkLogoImage)
      data.append('appDarkLogoImage', formData.appDarkLogoImage);
    if (formData.appLightLogoImage)
      data.append('appLightLogoImage', formData.appLightLogoImage);
    if (formData.backgroundDesktopImage)
      data.append('backgroundDesktopImage', formData.backgroundDesktopImage);
    if (formData.backgroundTabletImage)
      data.append('backgroundTabletImage', formData.backgroundTabletImage);
    if (formData.backgroundMobileImage)
      data.append('backgroundMobileImage', formData.backgroundMobileImage);

    dispatch(
      submitChartDataRequest(data, () => {
        dispatch(getChartDataRequest(activeTabContent?.id));
      }),
    );
  };

  useEffect(() => {
    if (activeTabContent?.id === undefined) {
      return;
    }
    dispatch(getChartDataRequest(activeTabContent?.id));
  }, [activeTabContent?.id]);

  if (loading) return <div>Loading...</div>;
  //if (error) return <div>Somthing went wrong</div>;

  return (
    <form action="">
      <div className="CommandeEnLigne h-full fl_col gp10">
        <div className="fl_row jc_s ai_c">
          <p className="p22 w-700 Independence-txt">Charte graphique</p>
          <button className="save-btn" onClick={handleSubmit}>
            <img src={SaveIconWhiteBtn} alt="" />
            Sauvegarder
          </button>
        </div>
        <div className="charte-graphique section-wrapper fl_row gp20 h-full">
          <div className="tabs-list">
            <div className="fl_col gp20">
              <div className="colors-changer fl_col gp10">
                <SelectColor
                  label="Primary Color"
                  id="primaryColor"
                  value={formData?.primaryColor || data?.primaryColor}
                  onChange={handleChange}
                />
                <SelectColor
                  label="Secondary Color"
                  id="secondaryColor"
                  value={formData?.secondaryColor || data?.secondaryColor}
                  onChange={handleChange}
                />
                <SelectColor
                  label="Layout Color"
                  id="layoutColor"
                  value={formData?.layoutColor || data?.layoutColor}
                  onChange={handleChange}
                />
              </div>
              <div className="imgs-upload fl_col gp10">
                <SelectImage
                  label={'Logo'}
                  onChange={handleChange}
                  src={data?.appLogoImage}
                  id="appLogoImage"
                />
                <SelectImage
                  label={'Logo Dark '}
                  onChange={handleChange}
                  src={data?.appDarkLogoImage}
                  id="appDarkLogoImage"
                />
                <SelectImage
                  label={'Logo Light'}
                  onChange={handleChange}
                  src={data?.appLightLogoImage}
                  id="appLightLogoImage"
                />
                <SelectImage
                  label={'Background Desktop'}
                  onChange={handleChange}
                  src={data?.backgroundDesktopImage}
                  id="backgroundDesktopImage"
                />
                <SelectImage
                  label={'Background Tablet'}
                  onChange={handleChange}
                  src={data?.backgroundTabletImage}
                  id="backgroundTabletImage"
                />
                <SelectImage
                  label={'Background Mobile'}
                  onChange={handleChange}
                  src={data?.backgroundMobileImage}
                  id="backgroundMobileImage"
                />
              </div>
            </div>
          </div>

          <div className="tabs-content fl-1 pd12 rd8"></div>
        </div>
      </div>
    </form>
  );
}
