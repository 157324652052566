import React, { ChangeEvent, useEffect, useState } from 'react';
import BottomArrowGreyIcon from '../../assets/icons/BottomArrowGreyIcon.svg';
import FrFlagCircle from '../../assets/icons/FrFlagCircle.svg';
import SearchIconGreyInp from '../../assets/icons/SearchIconGreyInp.svg';
import ClearInputGrey from '../../assets/icons/ClearInputGrey.svg';
import InputField from './Input';
import OptionItem from './OptionItem';
import { languageData } from '../../i18n';

interface SelectInputProps {
  label?: string;
  name?: string;
  type?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  style?: string;
  isDisabled?: boolean;
  haserror?: boolean;
  errorMsg?: string;
  placeholder?: string;
  options: string[];
  icons?: string[];
  target: 'country' | 'dropdown' | 'search' | 'reference' | 'reflags';
  onKeyUp?: () => void;
  onSelect?: (
    selectedValue: string,
    selectedIcon?: string,
    reference?: string,
  ) => void;
  reference?: string[];
  isRequired?: boolean;
  defaultValue?: string | number | undefined;
  value?: string | number | undefined;
  setidEntr?: React.Dispatch<React.SetStateAction<number | undefined>>;
  setidEtab?: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const SelectInput: React.FC<SelectInputProps> = ({
  label,
  name,
  type,
  style,
  isDisabled,
  placeholder,
  icons,
  options,
  onChange,
  target,
  onKeyUp,
  onSelect,
  haserror,
  errorMsg,
  reference,
  isRequired,
  defaultValue,
  setidEntr,
  setidEtab,
  value,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [inputValue, setInputValue] = useState<string | number>('');
  const [inputIcon, setInputIcon] = useState('');
  const [localHasError, setLocalHasError] = useState(haserror || false);
  const [filteredItemse, setFilteredItemse] = useState<any[]>(options);
  const [isLoading, setIsLoading] = useState(true); // New state to control loading

  useEffect(() => {
    setFilteredItemse(
      options.map((option, index) => ({
        option,
        icon: icons && icons.length > index ? icons[index] : undefined,
        reference: reference ? reference[index] : undefined,
      })),
    );
  }, [options, reference, icons]);
  
  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
    }
    if (typeof defaultValue === 'string' && defaultValue === '') {
      setInputValue('');
    }
  }, [defaultValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 20000);
    return () => clearTimeout(timeout);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    filterItems(value);
    setShowDropdown(true);
  };

  useEffect(() => {
    setLocalHasError(haserror || false);
  }, [haserror]);

  const handleOptionClick = (
    optionValue: string,
    optionIcon?: string,
    ref?: string,
  ) => {
    setInputValue(optionValue);
    setShowDropdown(false);

    if (onSelect) {
      onSelect(optionValue, optionIcon, ref);
    }
  };

  const filterItems = (value: string) => {
    if (value.trim() === '') {
      setFilteredItemse(
        options.map((option, index) => ({
          option,
          icon: icons && icons.length > index ? icons[index] : undefined,
          reference: reference ? reference[index] : undefined,
        })),
      );
    } else {
      const filtered = options
        .map((option, index) => {
          const referenceVal = reference ? reference[index] : undefined;
          const optionLower = option.toLowerCase();
          const referenceLower = referenceVal ? referenceVal.toLowerCase() : '';

          if (
            optionLower.includes(value.toLowerCase()) ||
            referenceLower.includes(value.toLowerCase())
          ) {
            return {
              option,
              icon: icons && icons.length > index ? icons[index] : undefined,
              reference: referenceVal,
            };
          }
          return null;
        })
        .filter(Boolean);

      setFilteredItemse(filtered);
    }
  };

  return (
    <div
      className={`InputFieldDropDown ${showDropdown ? 'DisplayList' : ''} fl_col gp8 ${target}_field ${
        localHasError ? 'haserror' : ''
      }`}
    >
      {showDropdown && (
        <div
          className="select-overlay"
          onClick={() => setShowDropdown(false)}
        ></div>
      )}

      <div className="inp-select-field">
        <div className="inp fl_col gp8">
          <img className="icon-dropdown" src={BottomArrowGreyIcon} alt="" />
          <InputField
            value={inputValue}
            onChange={handleChange}
            name={name}
            type="text"
            placeholder={placeholder}
            label={label}
            style="form"
            isDisabled={isDisabled}
            onClick={() => setShowDropdown((prevState) => !prevState)}
            haserror={localHasError}
            isRequired={isRequired}
            onKeyUp={onKeyUp}
          />
          <div className={`InputDropDownList ${showDropdown ? '' : 'hide'}`}>
            <ul className="fl_col gp10 pd10-t-b">
              {filteredItemse?.length > 0 ? (
                filteredItemse?.map(
                  (item: any, index: any) =>
                    item && (
                      <OptionItem
                        key={index}
                        target={target}
                        value={item.option}
                        icon={item.icon}
                        reference={item.reference}
                        onClick={(value, icon, ref) =>
                          handleOptionClick(value, icon, ref)
                        }
                      />
                    ),
                )
              ) : isLoading ? (
                <li className="pd4-t-b pd10-r-l jc_c">
                  <div style={{ marginLeft: '-20px' }} className="fl_row jc_c">
                    <span className="loader-select DarkBlueGray-txt">
                      Chargement
                    </span>
                  </div>
                </li>
              ) : (
                <li className="pd4-t-b pd10-r-l jc_c p14">
                  {languageData.fr.card.noResult}
                </li>
              )}
            </ul>
          </div>
        </div>
        {inputValue && (
          <img
            src={ClearInputGrey}
            alt=""
            className="clear-select"
            onClick={() => {
              setInputValue('');
              setidEntr && setidEntr(undefined);
              setidEtab && setidEtab(undefined);
            }}
          />
        )}
      </div>
      {localHasError && errorMsg && <p className="p12 errorMsg">{errorMsg}</p>}
    </div>
  );
};

export default SelectInput;
