import React, { useState } from 'react';
import SwitcherCheckbox from '../../../../components/Catalogue/SwitcherCheckbox';
import InputField from '../../../../components/Input/Input';
import TimerSelect from './TimerSelect';
import SaveIconWhiteBtn from '../../../../assets/icons/SaveIconWhiteBtn.svg';

interface ModeLivraisonProps {
  title?: string;
}

const ModeLivraison: React.FC<ModeLivraisonProps> = ({ title }) => {
  const [activeModes, setActiveModes] = useState<Record<string, boolean>>({
    'sur-place': false,
    'a-emporter': false,
    'en-livraison': false,
  });

  const handleSwitcherChange = (mode: string) => {
    setActiveModes((prevModes) => ({
      ...prevModes,
      [mode]: !prevModes[mode],
    }));
  };

  return (
    <div className="ModeLivraison tab-content-section">
      <div className="block-wrapper fl_col gp20">
        <div className="fl_row gp20">
          <SwitcherCheckbox
            ischecked={activeModes['sur-place']}
            onChange={() => handleSwitcherChange('sur-place')}
            label="Sur place"
          />
          <SwitcherCheckbox
            ischecked={activeModes['a-emporter']}
            onChange={() => handleSwitcherChange('a-emporter')}
            label="A Emporter"
          />
          <SwitcherCheckbox
            ischecked={activeModes['en-livraison']}
            onChange={() => handleSwitcherChange('en-livraison')}
            label="En livraison"
          />
        </div>
        <div className="fl_col gp20">
          {(activeModes['sur-place'] || activeModes['a-emporter']) && (
            <TimerSelect dropdown label="Temps moyen de récupération" />
          )}
          {activeModes['en-livraison'] && (
            <>
              <TimerSelect dropdown label="Temps moyen de Livraison" />
              <InputField
                name="share_capital"
                type="number"
                value={0}
                label="Seuil de panier (Livraison)"
                style="form"
              />
              <InputField
                name="share_capital"
                type="number"
                value={0}
                label="Rayon de livraison (Km)"
                style="form"
              />
            </>
          )}
        </div>
      </div>
      {/* <div className="fl_row jc_fe">
        <button className="save-btn">
          <img src={SaveIconWhiteBtn} alt="" />
          Sauvegarder
        </button>
      </div> */}
    </div>
  );
};

export default ModeLivraison;
