import { AxiosResponse } from 'axios';
import { put } from 'redux-saga/effects';
import {
  submitChartDataSuccess,
  submitChartDataFailure,
  getChartDataFailure,
  getChartDataSuccess,
} from '../actions/actions';
import commandEnLigneApi from '../../services/apiHandlers/commandEnLigne';

export function* getCharCompanySaga(action: {
  type: string;
  payload: { CompanyId: any };
}) {
  const { CompanyId } = action.payload;
  try {
    const response: AxiosResponse =
      yield commandEnLigneApi.getChartData(CompanyId);
    if (response?.data) {
      yield put(getChartDataSuccess(response.data.result));
    }
  } catch (error: any) {
    yield put(getChartDataFailure(error.message));
  }
}
export function* handleSubmitCompanyDataSaga(action: {
  type: string;
  payload: any;
  _?: () => void;
}) {
  try {
    const response: AxiosResponse = yield commandEnLigneApi.postChartData(
      action.payload,
    );
    if (response.data) {
      yield put(submitChartDataSuccess(response.data.result));
      action?._ && action?._();
    }
  } catch (error: any) {
    yield put(submitChartDataFailure(error.message));
  }
}
